<template>
<div>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Filtreleme
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Sınıf/Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Alt Sınıf</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple v-model="filterRequest.childClassId" :options="childClass" class="w-100" :reduce="val => val.class.id" :getOptionLabel="option => option.class.name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Alt Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Konu</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTopic" :options="topics" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Video Grup</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideoGroup" :options="videoGroups" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Test</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTest" :options="tests" class="w-100" :reduce="val => val.id" label="title" />
                </b-col>

                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <b-row class="mt-2">
                         <b-col cols="12" md="6" class="mb-md-0">
                             <b-button block variant="success" @click="getTestData()">FİLTRELE</b-button>
                         </b-col>
                          <b-col cols="12" md="6" class="mb-md-0">
                             <b-button target="_blank" block @click="downloadExcel()" variant="primary" class="font-weight-bolder mb-12">Excell İndir</b-button>
                         </b-col>
                    </b-row>
                </b-col>

            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>
    </b-card>

    <app-collapse type="margin">
        <app-collapse-item title="Test Bazlı Rapor">
            <b-card v-if="users" no-body class="card-company-table">
                <b-table :items="users" responsive :fields="fields" class="mb-0">
                    <!-- Id -->
                    <template #cell(id)="data">
                        <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                    </template>

                    <template #cell(testName)="data">
                        <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="data.toggleDetails">{{data.item.testName }}</b-button>
                    </template>

                    <template #cell(exitCount)="data">
                        <span class="font-weight-bolder mb-12" v-if="data.item.exitCount==0">
                            {{data.item.exitCount }}
                        </span>
                        <b-button block variant="flat-primary" v-if="data.item.exitCount>0" class="font-weight-bolder" @click="sendNotification(data.item)">{{data.item.exitCount }} Kişiye Bildirim</b-button>
                    </template>

                    <template #cell(successRate)="data">
                        <span class="font-weight-bolder mb-12">{{data.item.successRate.toFixed(2) }}%</span>
                    </template>

                    <template #row-details="data">
                        <b-card>
                            <b-card-body>
                                <b-tabs align="center">
                                    <b-tab active title="Başarılı Öğrenciler">
                                        <b-table :items="data.item.details.users.filter(x=>x.isSuccess==true)" responsive :fields="userSuccessFields" class="mb-0">
                                            <template #cell(idNumber)="data">
                                                <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="openUserWatch(data.item.id)">{{data.item.idNumber }}</b-button>
                                            </template>
                                        </b-table>
                                    </b-tab>
                                    <b-tab title="Başarısız Öğrenciler">
                                        <b-table :items="data.item.details.users.filter(x=>x.isSuccess==false && x.isExit==false)" responsive :fields="userFailFields" class="mb-0">
                                            <template #cell(idNumber)="data">
                                                <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="openUserWatch(data.item.id)">{{data.item.idNumber }}</b-button>
                                            </template>
                                        </b-table>
                                    </b-tab>
                                    <b-tab title="Girmeyen Öğrenciler">
                                        <b-table :items="data.item.details.users.filter(x=>x.isExit==true)" responsive :fields="userSuccessFields" class="mb-0">
                                            <template #cell(idNumber)="data">
                                                <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="openUserWatch(data.item.id)">{{data.item.idNumber }}</b-button>
                                            </template>
                                        </b-table>
                                    </b-tab>
                                    <b-tab title="Yanlış Yapılan Sorular">
                                        <b-table :items="data.item.details.questions" responsive :fields="questionFields" class="mb-0">
                                            <template #cell(referance)="data">
                                                <a target="_blank" :href="'https://board.kurssenle.com/?referance='+data.item.referance" class="font-weight-bolder mb-12">Aç</a>
                                            </template>
                                        </b-table>
                                    </b-tab>
                                    <b-tab title="Boş Bıraklan Sorular">
                                        <b-table :items="data.item.details.empty" responsive :fields="questionFields" class="mb-0">
                                            <template #cell(referance)="data">
                                                <a target="_blank" :href="'https://board.kurssenle.com/?referance='+data.item.referance" class="font-weight-bolder mb-12">Aç</a>
                                            </template>
                                        </b-table>
                                    </b-tab>
                                </b-tabs>
                            </b-card-body>
                        </b-card>
                    </template>

                </b-table>
            </b-card>
        </app-collapse-item>
        <app-collapse-item title="Öğrenci Bazlı Rapor">
            <b-card no-body class="card-company-table">
                <b-table :items="userData" responsive class="mb-0"></b-table>
            </b-card>
        </app-collapse-item>
    </app-collapse>
</div>
</template>

<script>
import XLSX from 'xlsx';
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    BTabs,
    BTab
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress,
        BOverlay,
        BTabs,
        BTab
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'testName', label: 'Testin Adı' },
                { key: 'studentCount', label: 'Toplam Öğrenci Sayısı' },
                { key: 'successCount', label: 'Başarılı Öğrenci' },
                { key: 'failCount', label: 'Başarısız Öğrenci' },
                { key: 'exitCount', label: 'Girmeyen Öğrenci' },
                { key: 'successRate', label: 'Başarı Yüzdesi' },
            ],
            questionFields: [
                { key: 'number', label: 'Soru' },
                { key: 'count', label: 'Toplam' },
                { key: 'referance', label: 'İşlem' },
            ],
            userSuccessFields: [
                { key: 'idNumber', label: 'Öğrenci Tc' },
                { key: 'fullName', label: 'Öğrenci Adı Soyadı' },
                { key: 'correct', label: 'Doğru Sayısı' },
                { key: 'empty', label: 'Boş Sayısı' },
                { key: 'wrong', label: 'Yanlış Sayısı' },
                { key: 'userPoint', label: 'Öğrencinin Puanı' }
            ],
            userFailFields: [
                { key: 'idNumber', label: 'Öğrenci Tc' },
                { key: 'fullName', label: 'Öğrenci Adı Soyadı' },
                { key: 'failCount', label: 'Yanlış Sayısı' },
                { key: 'answers', label: 'Cevaplar' },
                { key: 'correct', label: 'Doğru Sayısı' },
                { key: 'empty', label: 'Boş Sayısı' },
                { key: 'wrong', label: 'Yanlış Sayısı' },
                { key: 'userPoint', label: 'Öğrencinin Puanı' }
            ],
            status: [
                { key: 2, label: 'Devam Ediyor' },
                { key: 4, label: 'Sonuçlar İşleniyor' },
                { key: 5, label: 'Sonuçlandı' },
            ],
            selectedLesson: null,
            selectedChildLesson: null,
            selectedTopic: null,
            selectedVideoGroup: null,
            selectedTest: null,
            users: [],
            lessons: [],
            childClass: [],
            childLessons: [],
            videoGroups: [],
            topics: [],
            tests: [],
            userData: [],
            downloadUrl: "",
            filterRequest: {
                lessnId: '',
                childClassId: '',
                childLessonId: '',
                status: 4
            }
        }
    },
    created() {
        this.getLesson();
    },
    watch: {
        selectedLesson: function (val) {
            this.getChildLesson();
            this.childClass = [];
            this.filterRequest.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter(x => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
        selectedChildLesson: function () {
            this.getTopics();
        },
        selectedTopic: function () {
            this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            this.getTests();
        }
    },
    methods: {
        openUserWatch(userId) {
            let routeData = this.$router.resolve({ name: 'watchingUser', params: { uid: userId } });
            window.open(routeData.href, "Öğrenci Hareketleri", 'height=800,width=800');
        },
        async sendNotification(item) {
            //console.log(item);
            var users = item.details.users.filter(x => x.isExit == true);
            var request = users.map(x => x.id);
            var response = await this.$http.post("Report/SendNotification/" + item.testId, request);
            //console.log(response);
        },
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },

        async getChildLesson() {
            this.filterRequest.childLessonId = "";
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                data = data[0];
                var childLesson = await this.$http.get("Teacher/ChildLessons/" + data.lesson.id + "/" + data.class.id);
                this.childLessons = childLesson.data.data;
            }
        },

        async getTopics() {
            var topics = await this.$http.get("Teacher/Topics/" + this.selectedChildLesson);
            this.topics = topics.data.data;
        },

        async getVideoGroup() {
            var videoGroups = await this.$http.get("Teacher/VideoGroup/" + this.selectedTopic);
            this.videoGroups = videoGroups.data.data;
        },

        async getTests() {
            var tests = await this.$http.get("Teacher/Test/" + this.selectedVideoGroup);
            this.tests = tests.data.data;
        },

        async getTestData() {
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                this.loading = true;
                data = data[0];

                var request = {
                    lessonId: data.lesson.id,
                    classId: "00000000-0000-0000-0000-000000000000",
                    childLessonId: "00000000-0000-0000-0000-000000000000",
                    topicId: "00000000-0000-0000-0000-000000000000",
                    videoGroupId: "00000000-0000-0000-0000-000000000000",
                    testId: "00000000-0000-0000-0000-000000000000",
                    status: null,
                    class: []
                };

                if (this.filterRequest.childClassId != null) { request.class = this.filterRequest.childClassId; }
                if (this.selectedChildLesson != null) { request.childLessonId = this.selectedChildLesson; }
                if (this.selectedTopic != null) { request.topicId = this.selectedTopic; }
                if (this.selectedVideoGroup != null) { request.videoGroupId = this.selectedVideoGroup; }
                if (this.selectedTest != null) { request.testId = this.selectedTest; }
                if (this.filterRequest.status != null) { request.status = this.filterRequest.status; }

                var lesson = await this.$http.post("Report/TestRates", request);
                this.users = lesson.data.data;
                this.loading = false;
                var userData = await this.$http.post("Report/TestRatesUser", request);
                this.userData = userData.data.data.data;
                this.downloadUrl = userData.data.data.downloadUrl;

            }
            
        },
       
       async downloadExcel(){
            var ws_data = new Array();
            var lessonsrow=new Array();
            
        
            for (const key in this.userData[0]) {
                if(key!="idNumber"){
                 lessonsrow.push(key);
                }else if(key=="class"){
                    key="Sınıf";
                }else if(key=="studentName"){
                    key="İsim Soyisim";
                }
            }
            ws_data.push(lessonsrow);

            this.userData.filter(function(user){
                
                var newrow= new Array();
                for (const key in user) {
                if(key!="idNumber"){
                    newrow.push(user[key]);
                    }
                }
 
                ws_data.push(newrow);
            })

            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Percent Report",
                Subject: "Percent Report",
                Author: "Yes Akademi",
                CreatedDate: new Date()
             };
            
             var ws = XLSX.utils.aoa_to_sheet(ws_data);
             wb.SheetNames.push("Sayfa1");     
             ws["!cols"] = [ { width: 20 },{ width: 30 }]; //student names width
             for (let index = 0; index < 30; index++) {
                 ws["!cols"].push( { width: 25 }); 
             }
            
             wb.Sheets["Sayfa1"] = ws; 
             await XLSX.writeFile(wb,   "percentReport.xlsx");



            
        },


    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
